import { GET, PATCH } from "../../util/apiHelpers";

export const RECEIVE_CONTRACT_REMITTANCE_EMAILS = "RECEIVE_CONTRACT_REMITTANCE_EMAILS";

export const receiveContractRemittanceSubscriptions = (data) => ({
  type: RECEIVE_CONTRACT_REMITTANCE_EMAILS,
  payload: data,
});

export const fetchContractRemittanceSubscriptions = () => (dispatch) =>
  GET("/api/v3/recruiter/organization/contract_remittance_subscriptions")
    .then((res) => dispatch(receiveContractRemittanceSubscriptions(res.data)));

export const updateContractRemittanceSubscriptions = (emails) => (dispatch) =>
  PATCH("/api/v3/recruiter/organization/update_contract_remittance_subscriptions", { emails })
    .then((res) => dispatch(receiveContractRemittanceSubscriptions(res.data)));
