import { combineReducers } from "redux";

import { candidateReducer } from "./candidateReducer";
import certificationReducer from "./certificationReducer";
import communityTerms from "./communityTermsReducer";
import contractReducer from "./contractReducer";
import contractRemittanceSubscriptionReducer from './contractRemittanceSubscriptionReducer';
import { conversationReducer } from "./conversationReducer";
import costCenterReducer from "./costCenterReducer";
import draftReducer from "./draftReducer";
import employeeWorkHistoryReducer from "./employeeWorkHistoryReducer";
import featureFlagReducer from "./featureFlagReducer";
import filters from "./filtersReducer";
import finance from "./finance/financeReducer";
import { individualReducer } from "./individualReducer";
import insuranceRequirementAgreementReducer from "./insuranceRequirementAgreementReducer";
import insuranceRequirementReducer from "./insuranceRequirementReducer";
import invitations from "./invitations/invitationsReducer";
import jobcastReducer from "./jobcastReducer";
import jobcastUpdates from "./jobcastUpdates/jobcastUpdatesReducer";
import notifications from "./notificationReducer";
import organizationProfileReducer from "./organizationProfileReducer";
import organizationReducer from "./organizationReducer";
import orgJobCategoryReducer from "./orgJobCategoryReducer";
import payPeriodReducer from "./payPeriodReducer";
import preferredLinkReducer from "./preferredLinkReducer";
import { recruiterApplicationReducer } from "./recruiterApplicationReducer";
import recruiterListReducer from "./recruiterListReducer";
import { recruiterSubmissionReducer, recruiterSubmissionsReducer } from "./recruiterSubmissionReducer";
import releaseToken from "./releaseTokenReducer";
import { requestReducer } from "./requestReducer";
import requiredTempAgencyAgreementReducer from "./requiredTempAgencyAgreementReducer";
import reviews from "./reviewsReducer";
import { searchReducer } from "./searchReducer";
import settingsReducer from "./settings/settingsReducer";
import tempPrerequisiteReducer from "./tempPrerequisiteReducer";
import ui from "./ui/uiReducer";

const appReducer = combineReducers({
  ui,
  // entities...
  candidates: candidateReducer,
  certifications: certificationReducer,
  contracts: contractReducer,
  communityTerms,
  employeeWorkHistories: employeeWorkHistoryReducer,
  conversations: conversationReducer,
  costCenters: costCenterReducer,
  requiredTempAgencyAgreements: requiredTempAgencyAgreementReducer,
  orgJobCategories: orgJobCategoryReducer,
  insuranceRequirementAgreements: insuranceRequirementAgreementReducer,
  insuranceRequirements: insuranceRequirementReducer,
  tempPrerequisites: tempPrerequisiteReducer,
  drafts: draftReducer,
  featureFlags: featureFlagReducer,
  filters,
  finance,
  individuals: individualReducer,
  invitations,
  jobcasts: jobcastReducer,
  jobcastUpdates,
  notifications,
  organizationProfiles: organizationProfileReducer,
  organizations: organizationReducer,
  contractRemittanceSubscriptions: contractRemittanceSubscriptionReducer,
  payPeriods: payPeriodReducer,
  preferredLinks: preferredLinkReducer,
  recruiterApplications: recruiterApplicationReducer,
  recruiterLists: recruiterListReducer,
  recruiterSubmissions: recruiterSubmissionReducer,
  totalRecruiterSubmissions: recruiterSubmissionsReducer,
  releaseToken,
  requests: requestReducer,
  reviews,
  search: searchReducer,
  settings: settingsReducer,
});

const RootReducer = (state, action) => {
  // sets state as undefined.
  // all reducers will revert to default state
  if (action.type === "SUCCESSFUL_LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default RootReducer;
