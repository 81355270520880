import { withStyles } from "@material-ui/core/styles";
import merge from "lodash/merge";
import React from "react";

import CandidateChip from "../../../../General/CandidateChip";

import sharedStyles from "../sharedStyles";

const styles = () => merge(sharedStyles, {
  participantProfile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginRight: "12px"
  },
  chipHeader: {
    color: "#90A4AE",
    marginBottom: "5px"
  }
});

function Subheader({
  classes, leftChip, rightChip, headerText
}) {
  return (
    <div className={classes.subheaderContainer}>
      {headerText && <h5>{headerText}</h5>}
      {leftChip && (
        <div className={classes.participantProfile}>
          <div className={`overline-heading ${classes.chipHeader}`}>
            {leftChip.chipHeader}
          </div>
          <CandidateChip
            individual={{
              firstName: leftChip.individualFirstName,
              lastName: leftChip.individualLastInitial,
              avatarIcon: leftChip.individualAvatar,
            }}
            name={`${leftChip.individualFirstName} ${leftChip.individualLastInitial}`}
            type="chatting-with"
            info={leftChip.chipSubText}
          />
        </div>
      )}
      {rightChip && (
        <div className={classes.participantProfile}>
          <div className={`overline-heading ${classes.chipHeader}`}>
            {rightChip.chipHeader}
          </div>
          <CandidateChip
            individual={{
              firstName: rightChip.individualFirstName,
              lastName: rightChip.individualLastInitial,
              avatarIcon: rightChip.individualAvatar,
            }}
            name={`${rightChip.individualFirstName} ${rightChip.individualLastInitial}`}
            type="candidate"
            info={rightChip.chipSubText}
            onNameClick={rightChip.onNameClick}
          />
        </div>
      )}
    </div>
  );
}

export default withStyles(styles)(Subheader);
