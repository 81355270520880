import { withStyles } from "@material-ui/core/styles";
import merge from "lodash/merge";
import React, { Component } from "react";
import { connect } from "react-redux";

import {
  fetchCandidate,
  fetchConversation,
  createMessage,
} from "../../../../actions/employers/candidateActions";

import {
  openModal,
  openProfileDrawer,
  setNotificationsDrawerVisible,
} from "../../../../actions/shared/uiActions";

import { getCandidate } from "../../../../selectors/candidateSelectors";
import { getCandidateConversation } from "../../../../selectors/conversationSelector";
import CandidateChip from "../../../General/CandidateChip";
import LoadingPage from "../../../General/LoadingPage";

import ChatTab from "../../../shared/CandidateDrawer/CandidateDrawerTabs/ChatTab";

import sharedStyles from "../../../shared/NotificationsDrawer/NotificationViewTab/sharedStyles";
import AgencyRecruiterDrawer from "../../Agencies/view/AgencyRecruiterDrawer";
import { AGENCY_CHAT } from "../../JobCasts/view/Candidates/constants";
import CandidateDrawer from "../../JobCasts/view/Candidates/view/CandidateDrawer";

const styles = (theme) => merge(sharedStyles, {
  subheaderContainer: {
    justifyContent: "space-between",
  },
  participantProfile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginRight: "12px",
  },
  profileDetails: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 50,
  },
  profileText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  chipHeader: {
    color: "#90A4AE",
    marginBottom: "5px",
  },
});

class AgencyChat extends Component {
  fetchData() {
    const { fetchCandidate, fetchConversation } = this.props;

    fetchCandidate().then(fetchConversation);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate({ loaded: prevLoaded }) {
    const { loaded } = this.props;

    // specifically happens when we select another chat notification
    // from the same index
    if (prevLoaded && !loaded) {
      this.fetchData();
    }
  }

  handleAgencyDrawerOpen() {
    const {
      setNotificationsDrawerNotVisible,
      openProfileDrawer,
      conversation: {
        attributes: { otherParticipantId },
      },
    } = this.props;

    setNotificationsDrawerNotVisible();

    return openProfileDrawer(
      <AgencyRecruiterDrawer recruiterId={otherParticipantId} />
    );
  }

  handleCandidateDrawerOpen() {
    const {
      setNotificationsDrawerNotVisible,
      openProfileDrawer,
      conversation: {
        attributes: { recruiterSubmissionId },
      },
    } = this.props;
    setNotificationsDrawerNotVisible();

    return openProfileDrawer(
      <CandidateDrawer
        candidateId={recruiterSubmissionId}
        navSelection={AGENCY_CHAT}
      />
    );
  }

  renderHeader() {
    const { classes, candidate, conversation } = this.props;

    const {
      otherParticipantFirstName,
      otherParticipantLastName,
      otherParticipantOrganization,
      otherParticipantAvatar,
    } = conversation.attributes;

    const {
      firstName: candidateFirstName,
      lastName: candidateLastName,
      avatar: candidateAvatar,
    } = candidate.attributes;

    return (
      <div className={classes.subheaderContainer}>
        <div className={classes.participantProfile}>
          <div className={`overline-heading ${classes.chipHeader}`}>Chatting with</div>
          <CandidateChip
            individual={{
              firstName: otherParticipantFirstName,
              lastName: otherParticipantLastName,
              avatarIcon: otherParticipantAvatar,
            }}
            name={`${otherParticipantFirstName} ${otherParticipantLastName}`}
            type="chatting-with"
            info={otherParticipantOrganization}
            onNameClick={() => this.handleAgencyDrawerOpen()}
          />
        </div>
        <div className={classes.participantProfile}>
          <div className={`overline-heading ${classes.chipHeader}`}>
            About candidate
          </div>
          <CandidateChip
            individual={{
              firstName: candidateFirstName,
              lastName: candidateLastName,
              avatarIcon: candidateAvatar,
            }}
            name={`${candidateFirstName} ${candidateLastName}`}
            type="candidate"
            info={candidate.attributes.status}
            overrideStyle={{
              backgroundColor: "white",
              border: ".5px solid #B0BEC5",
            }}
            onNameClick={() => this.handleCandidateDrawerOpen()}
          />
        </div>
      </div>
    );
  }

  renderChat() {
    return (
      <div style={{ height: "calc(100% - 150px)" }}>
        <ChatTab
          createMessage={this.props.createMessage}
          fetchConversation={this.props.fetchConversation}
          candidate={this.props.candidate}
          jobcast={this.props.jobcast}
          multiTextStyle={{
            width: "65%",
            paddingTop: 12,
          }}
        />
      </div>
    );
  }

  render() {
    const { loaded } = this.props;

    if (loaded) {
      return (
        <>
          {this.renderHeader()}
          {this.renderChat()}
        </>
      );
    }
    return <LoadingPage />;
  }
}

const mapStateToProps = (state, ownProps) => {
  const candidate = getCandidate(state, ownProps.candidateId);
  const conversation = getCandidateConversation(state, ownProps.candidateId);
  return {
    candidate,
    conversation,
    loaded: Boolean(candidate && conversation),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchCandidate: () => dispatch(fetchCandidate(ownProps.candidateId)),
  fetchConversation: () => dispatch(fetchConversation(ownProps.candidateId)),
  createMessage: (message) => dispatch(createMessage(ownProps.candidateId, message)),
  openModal: (content) => dispatch(openModal(content)),
  openProfileDrawer: (content) => dispatch(openProfileDrawer(content)),
  setNotificationsDrawerNotVisible: () => dispatch(setNotificationsDrawerVisible(false)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AgencyChat));
