import { includes } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import compose from "lodash/flowRight";
import get from "lodash/get";
import map from "lodash/map";
import unset from "lodash/unset";
import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, withRouter, Route } from "react-router-dom";

import { setViewingAs } from "../actions/employers/individualActions";
import { fetchUnansweredPositionCorrespondences } from "../actions/employers/jobcastUpdatesActions";
import { fetchNotifications as fetchEmployerNotifications } from "../actions/employers/notificationActions";

import { fetchOrganizationProfiles } from "../actions/employers/organizationProfileActions";
import { fetchTeammates as fetchEmployerTeammates } from "../actions/employers/teammateActions";
import { fetchNotifications as fetchRecruiterNotifications } from "../actions/recruiters/notificationActions";
import { fetchTeammates as fetchRecruiterTeammates } from "../actions/recruiters/teammateActions";
import { fetchCommunityTerms } from "../actions/shared/communityTermsActions";
import { fetchReleaseToken } from "../actions/shared/releaseTokenActions";
import { setHeaderMenuVisible } from "../actions/shared/uiActions";
import asyncComponent from "../AsyncComponent";
import { withBugsnag } from "../BugsnagWrapper";
import { getFeatureFlags } from "../selectors/featureFlagSelector";
import { getCurrentIndividual } from "../selectors/individualSelectors";
import { getCurrentOrganization } from "../selectors/organizationSelectors";
import { storageAvailable } from "../util/storageHelpers";

import PositionCorrespondencesConsumer from "./employer/ActionCable/subscriptions/PositionCorrespondencesConsumer";
import Modal from "./General/ConnectedModal";
import GDPRBanner from "./General/GDPRBanner";
import Intercom from "./General/Intercom";
import LoadingPage from "./General/LoadingPage";
import ProfileDrawer from "./General/ProfileDrawer";
import RelinkIntegrationBanner from "./General/RelinkIntegrationBanner";
import ViewingAsBanner from "./General/ViewingAsBanner";
import withSnackbar from "./General/withSnackbar";
import Header from "./Header/Header";
import NotificationsLeftDrawer from "./LeftDrawer/NotificationsLeftDrawer";
import PageLeftDrawer from "./LeftDrawer/PageLeftDrawer";
import CommunityCoinsConsumer from "./shared/ActionCable/subscriptions/CommunityCoinsConsumer";
import NotificationsConsumer from "./shared/ActionCable/subscriptions/NotificationsConsumer";
import ReleaseTokensConsumer from "./shared/ActionCable/subscriptions/ReleaseTokensConsumer";
import withReleaseRefresh from "./withReleaseRefresh";

// top level component imports
const AsyncAuthRoutes = asyncComponent(() =>
  import(
    /* webpackChunkName: "auth-routes" */
    "../routes/AuthRoutes"
  ));
const AsyncPublicRoutes = asyncComponent(() =>
  import(
    /* webpackChunkName: "public-routes" */
    "../routes/PublicRoutes"
  ));

const AsyncLoginRoutes = asyncComponent(() =>
  import(
    /* webpackChunkName: "login-routes" */
    "../routes/LoginRoutes"
  ));

class Main extends Component {
  componentDidMount() {
    this.props.fetchReleaseToken();
    this.props.fetchCommunityTerms();
  }

  componentDidUpdate(prevProps) {
    const {
      loggedIn,
      currentIndividual,
      currentOrganization,
      history,
      viewingAs,
      location,
      setHeaderMenuVisible,
    } = this.props;

    if (
      storageAvailable() &&
      location &&
      location.search &&
      queryString.parse(location.search).token
    ) {
      const query_params = queryString.parse(location.search);

      if (query_params.token !== global.localStorage.authToken) {
        global.localStorage.setItem("authToken", query_params.token);
        global.localStorage.setItem("ghosting", true);
      }

      history.push({
        search: queryString.stringify(unset(cloneDeep(query_params), "token")),
      });

      if (query_params.token !== global.localStorage.authToken) {
        window.location.reload();
      }
    }

    if (loggedIn && !prevProps.loggedIn) {
      this.props.updateBugsnagMetaData({
        user: currentIndividual,
        company: currentOrganization,
      });

      this.fetchAlerts();
      this.fetchTeammates();
      this.fetchOrganizationProfiles();

      if (storageAvailable() && global.localStorage.viewingAs) {
        this.props.setViewingAs(JSON.parse(global.localStorage.viewingAs));
      }

      if (history.location.pathname.match(/login/)) {
        const landingPage = currentIndividual.recruiter
          ? currentIndividual.talkAgency && !currentIndividual.agreedToRfiTerms
            ? "/talk-profile"
            : "/my-jobcasts"
          : "/jobcasts";

        history.push(currentIndividual.redirectPath || landingPage);
      }

      if (currentIndividual.snackbarMessage) {
        this.props.snackbar.showMessage(currentIndividual.snackbarMessage);
      }
    } else if (!loggedIn && prevProps.loggedIn) {
      storageAvailable() && global.localStorage.removeItem("ghosting");
      history.push("/login");
    }

    if (
      viewingAs &&
      ((!prevProps.viewingAs && viewingAs) ||
        viewingAs.key !== prevProps.viewingAs.key)
    ) {
      this.fetchAlerts();
    }

    const isActivated = Boolean(get(currentIndividual, "activatedAt"));
    if (
      isActivated !== Boolean(get(prevProps, "currentIndividual.activatedAt"))
    ) {
      setHeaderMenuVisible(isActivated);
    }
  }

  fetchAlerts = () => {
    const { fetchEmployerAlerts, fetchRecruiterAlerts, currentIndividual } =
      this.props;

    if (get(currentIndividual, "employer")) {
      fetchEmployerAlerts();
    } else if (get(currentIndividual, "recruiter")) {
      fetchRecruiterAlerts();
    }
  };

  fetchOrganizationProfiles = () => {
    const { currentIndividual, fetchOrganizationProfiles } = this.props;

    if (get(currentIndividual, "employer")) {
      fetchOrganizationProfiles();
    }
  };

  fetchTeammates = () => {
    const {
      currentIndividual,
      fetchRecruiterTeammates,
      fetchEmployerTeammates,
    } = this.props;

    if (get(currentIndividual, "employer")) {
      fetchEmployerTeammates();
    } else if (get(currentIndividual, "recruiter")) {
      fetchRecruiterTeammates();
    }
  };

  componentWillUnmount() {
    storageAvailable() && localStorage.removeItem("viewingAs");
  }

  renderLoading() {
    return <LoadingPage />;
  }

  renderRoutes() {
    return (
      <Switch>
        <Route path="/public" component={AsyncPublicRoutes} />
        {this.props.loggedIn ? (
          <Route path="/" component={AsyncAuthRoutes} />
        ) : (
          <Route path="/" component={AsyncLoginRoutes} />
        )}
      </Switch>
    );
  }

  renderSubscriptions() {
    const { currentIndividual } = this.props;

    const subscriptions = [];

    currentIndividual &&
      subscriptions.push(ReleaseTokensConsumer, NotificationsConsumer);

    get(currentIndividual, "employer") &&
      subscriptions.push(PositionCorrespondencesConsumer);

    get(currentIndividual, "recruiter") &&
      subscriptions.push(CommunityCoinsConsumer);

    return map(subscriptions, (SubscriptionClass) =>
      React.createElement(SubscriptionClass, { key: SubscriptionClass.name }));
  }

  render() {
    const { loading } = this.props;

    return (
      <div className="main-container-min-width" id="main-container">
        <NotificationsLeftDrawer />
        <RelinkIntegrationBanner />
        <Header />
        <div
          style={{
            display: "flex",
            width: "100%",
            minHeight: "calc(100% - 135px)",
            maxWidth: 1948,
          }}
        >
          <PageLeftDrawer />
          <Modal />
          <ProfileDrawer />
          <Intercom />
          {loading ? this.renderLoading() : this.renderRoutes()}
          <GDPRBanner />
          <ViewingAsBanner />
          {this.renderSubscriptions()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  viewingAs: state.individuals.viewing_as,
  loggedIn: Boolean(getCurrentIndividual(state)),
  currentIndividual: getCurrentIndividual(state),
  currentOrganization: getCurrentOrganization(state),
  featureFlags: getFeatureFlags(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchEmployerAlerts: () =>
    dispatch(fetchEmployerNotifications()).then(() =>
      dispatch(fetchUnansweredPositionCorrespondences())),
  fetchRecruiterAlerts: () => dispatch(fetchRecruiterNotifications()),
  fetchEmployerTeammates: () => dispatch(fetchEmployerTeammates()),
  fetchRecruiterTeammates: () => dispatch(fetchRecruiterTeammates()),
  setViewingAs: (value) => dispatch(setViewingAs(value)),
  setHeaderMenuVisible: (isVisible) =>
    dispatch(setHeaderMenuVisible(isVisible)),
  fetchOrganizationProfiles: () => dispatch(fetchOrganizationProfiles()),
  fetchReleaseToken: () => dispatch(fetchReleaseToken()),
  fetchCommunityTerms: () => dispatch(fetchCommunityTerms()),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withSnackbar(),
  withBugsnag,
  withReleaseRefresh
)(Main);
