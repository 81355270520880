import List from "@material-ui/core/List";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import orderBy from "lodash/orderBy";
import reduce from "lodash/reduce";
import React, { Component } from "react";
import { connect } from "react-redux";

import { getCurrentIndividual } from "../../../../selectors/individualSelectors";
import {
  makeGetFilteredNotifications,
  UNREAD
} from "../../../../selectors/notificationsSelectors";

import JobCastItem from "./JobCastItem";

import styles from "./styles";

class LiveJobCastsList extends Component {
  state = { jobcasts: [] };

  componentDidMount() {
    const { unreadNotifications, jobcasts, currentIndividual } = this.props;

    if (currentIndividual.employer) {
      this.setState({ jobcasts });
    } else {
      const results = reduce(
        unreadNotifications,
        (result, notification) => {
          const { jobcastId } = notification.attributes;
          result[jobcastId] = result[jobcastId] || 0;
          result[jobcastId] += 1;
          return result;
        },
        {}
      );

      const sortedResults = orderBy(jobcasts, jobcast => -(results[jobcast.id] || 0));

      this.setState({ jobcasts: sortedResults });
    }
  }

  render() {
    const {
      setSelectedId, selectedId, classes, currentIndividual
    } = this.props;
    return (
      <List className={classes.list}>
        <div
          className={classnames("overline-heading", "white")}
          style={{ padding: "0 12px 12px 24px" }}
          key="notifications-open-jobcasts"
        >
          {currentIndividual.employer ? "LIVE JOBCASTS" : "Alerts by JobCast"}
        </div>
        {this.state.jobcasts.map(jobcast => (
          <JobCastItem
            key={jobcast.attributes.id}
            jobcast={jobcast}
            setSelectedId={setSelectedId}
            selectedId={selectedId}
          />
        ))}
      </List>
    );
  }
}
const makeMapStateToProps = () => {
  const getFilteredNotifications = makeGetFilteredNotifications();

  const mapStateToProps = state => {
    const unreadNotifications = getFilteredNotifications(state, {
      [UNREAD]: true
    });
    const currentIndividual = getCurrentIndividual(state);

    return { currentIndividual, unreadNotifications };
  };

  return mapStateToProps;
};

export default connect(
  makeMapStateToProps,
  null
)(withStyles(styles)(LiveJobCastsList));
