import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Star from "@material-ui/icons/Star";
import React, { Component } from "react";
import { connect } from "react-redux";

import { advanceCandidate } from "../../../../../../../actions/employers/candidateActions";
import {
  clearModal,
  setProfileDrawerVisible,
} from "../../../../../../../actions/shared/uiActions";
import { getCurrentIndividual } from "../../../../../../../selectors/individualSelectors";
import { getJobCast } from "../../../../../../../selectors/jobcastSelectors";
import { getCurrentOrganization } from "../../../../../../../selectors/organizationSelectors";
import {
  inputValue,
  errorString,
  hasErrors,
  updateFormValue,
  validateFields,
} from "../../../../../../../util/formHelpers";
import MultiLineTextField from "../../../../../../forms/custom/MultiLineTextField";
import ActionModalContainer from "../../../../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../../../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../../../../../General/ActionModal/ActionModalFooter";
import ActionModalHeader from "../../../../../../General/ActionModal/ActionModalHeader";

import withSnackbar from "../../../../../../General/withSnackbar";

import { shouldInviteToATS } from "../constants";

// brychael-lezul: remove form helpers, switch to formik
class AdvanceModal extends Component {
  constructor(props) {
    super(props);
    this.nextStage =
      props.candidate.attributes.status === "reviewing"
        ? "Interviewing"
        : "Offers";

    this.reasonInputId = "advance_candidate_reason";

    this.state = {
      [this.reasonInputId]: inputValue(),
      submitted: false,
      loading: false,
      ats_invite: inputValue({ value: this.showAtsInvite() }),
    };

    this.inputErrorString = errorString.bind(this);
    this.inputHasErrors = hasErrors.bind(this);
    this.updateInputValue = updateFormValue.bind(this);
    this.validateFields = validateFields.bind(this);
    this.preSubmitValidations = {
      [this.reasonInputId]: { required: true, minWords: 3 },
    };
  }

  showAtsInvite = () => {
    const { sourceTrackingCode, atsSetup, candidateStage } =
      this.props.organization;
    const { atsApplicationUrl } = this.props.jobcast.attributes;
    const { status } = this.props.candidate.attributes;

    return (
      atsSetup &&
      Boolean(sourceTrackingCode) &&
      Boolean(atsApplicationUrl) &&
      Boolean(shouldInviteToATS[status]) &&
      shouldInviteToATS[status][candidateStage]
    );
  };

  handleSubmit = () => {
    if (this.state.submitted) {
    } else {
      this.validateFields(this.preSubmitValidations)
        .then(() => {
          this.setState({ submitted: true, loading: true });
          this.props
            .advanceCandidate({
              note: this.state[this.reasonInputId].value,
              ats_invite: this.state.ats_invite.value === true,
            })
            .then(() => {
              this.props.clearModal();
              this.props.snackbar.showMessage(
                "Candidate successfully advanced"
              );
            })
            .then(this.props.setProfileDrawerNotVisible);
        })
        .catch(this.setState({ loading: false }));
    }
  };

  renderFeedback = () => {
    const { feedbackRating } = this.props;
    if (!feedbackRating) {
      return <></>;
    }
    return (
      <div
        className="subtitle-2"
        style={{ display: "flex", alignItems: "center" }}
      >
        Your Responsiveness Rating
        <a
          href="/app/employer/perm/reports/my_ratings"
          style={{
            display: "flex",
            marginLeft: 8,
            textDecoration: "underline",
          }}
        >
          <Star />
          {this.props.feedbackRating}
        </a>
      </div>
    );
  };

  renderATSInvitation() {
    return (
      <div
        data-cy="invite-to-ats"
        style={{ display: "flex", flexDirection: "column", marginTop: 12 }}
      >
        <FormControlLabel
          control={
            <Checkbox
              id="ats_invite"
              checked={this.state.ats_invite.value}
              onChange={this.updateInputValue("ats_invite")}
              onBlur={() => {}}
              color="primary"
              data-cy="invite-to-ats-checkbox"
            />
          }
          label={<span>Email Candidate to apply into your ATS</span>}
          style={{
            paddingLeft: 14,
          }}
        />
      </div>
    );
  }

  renderContent() {
    const { candidate } = this.props;

    const showAtsInvite = this.showAtsInvite();

    return (
      <>
        <p style={{ margin: "0 0 24px 0" }}>
          {
            "You are advancing a candidate to the next stage in the hiring process. The agency will be notified. "
          }
          {showAtsInvite && (
            <strong>
              Given your company's settings, the candidate can be invited into
              your ATS at this time.
            </strong>
          )}
        </p>
        <MultiLineTextField
          error={this.inputHasErrors(this.reasonInputId)}
          helperText={this.inputErrorString(this.reasonInputId)}
          id={this.reasonInputId}
          label={`What are the next steps with ${candidate.attributes.firstName} ${candidate.attributes.lastName}?`}
          onChange={this.updateInputValue(this.reasonInputId, {
            minWords: 3,
            bounce: true,
          })}
          value={this.state.advance_candidate_reason.value}
          rows={3}
          placeholder="We will be moving to final interviews. He will be asked to read a book and write a funny review about it."
          style={{ marginBottom: 0 }}
        />
        {showAtsInvite && this.renderATSInvitation()}
      </>
    );
  }

  render() {
    const { candidate } = this.props;
    const { loading } = this.state;

    return (
      <ActionModalContainer color="blue">
        <ActionModalHeader
          title={`Advance Candidate to ${this.nextStage}`}
          subtitle={`${candidate.attributes.firstName} ${candidate.attributes.lastName}`}
        />
        <ActionModalContent>{this.renderContent()}</ActionModalContent>
        <ActionModalFooter
          actionText="Advance"
          handleAction={this.handleSubmit}
          isSubmitting={loading}
        >
          {this.renderFeedback()}
        </ActionModalFooter>
      </ActionModalContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const currentIndividual = getCurrentIndividual(state);
  const organization = getCurrentOrganization(state);
  const jobcast = getJobCast(state, ownProps.candidate.attributes.jobcastId);

  return {
    organization,
    jobcast,
    feedbackRating: currentIndividual.aggregateRatings.jobcastRatings
      ?.numReviews
      ? currentIndividual.aggregateRatings.jobcastRatings.RESPONSIVENESS?.average?.toFixed(
          2
        )
      : null,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  clearModal: () => dispatch(clearModal()),
  setProfileDrawerNotVisible: () => dispatch(setProfileDrawerVisible(false)),
  advanceCandidate: (data) =>
    dispatch(advanceCandidate(ownProps.candidate.id, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar()(AdvanceModal));
