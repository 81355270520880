import {
  RECEIVE_CONTRACT_REMITTANCE_EMAILS,
} from "../actions/recruiters/contractRemittanceSubscriptionActions";

const _defaultState = {
  items: {},
  loaded: false,
};

// eslint-disable-next-line default-param-last
export default function contractRemittanceSubscriptionReducer(state = _defaultState, action) {
  switch (action.type) {
    case RECEIVE_CONTRACT_REMITTANCE_EMAILS:
      return { items: action.payload, loaded: true };
    default:
      return state;
  }
}
