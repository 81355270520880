import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import classnames from "classnames";
import React from "react";

const styleThemes = {
  barContainer: {
    red: {
      backgroundColor: "rgb(255,109,109, 0.2)",
    },
    default: {
      backgroundColor: "rgb(144,164,174, 0.2)",
    },
  },
  iconContainer: {
    red: {
      backgroundColor: "#FF6D6D",
    },
    blue: {
      border: "1px solid #37474F",
      backgroundColor: "#008DAE",
    },
    green: {
      backgroundColor: "#3C9255",
    },
    default: {
      backgroundColor: "#90A4AE",
    },
  },
};

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    minWidth: 0,
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: 48,
    boxShadow: "none",
    borderBottom: "1px solid #37474F",
  },
  barContainer: {
    display: "flex",
    minWidth: 0,
    alignItems: "center",
    textAlign: "left",
    width: "100%",
    height: "100%",
    paddingRight: 24,
  },
  iconContainer: {
    width: 48,
    height: 48,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    color: "#FFF",
    height: 24,
    width: 24,
    "&$largeIcon": {
      height: 32,
      width: 32,
    },
  },
  subject: {
    paddingLeft: 12,
    paddingRight: 32,
    whiteSpace: "nowrap",
  },
  largeIcon: {},
}));

export default function AlertsBar(props) {
  const {
    theme = "default",
    largeIcon,
    subject,
    body,
    IconComponent,
    customMessage,
    displayTooltip = true,
  } = props;
  const classes = useStyles();

  const renderBody = () => {
    if (displayTooltip) {
      return (
        <Tooltip id="tooltip-alerts-bar-body" title={body || customMessage}>
          <span className="body small truncate">{body || customMessage}</span>
        </Tooltip>
      );
    }
    return (
      <span className="body small truncate">{body || customMessage}</span>
    );
  };

  return (
    <Paper className={classes.paper} square data-cy="jobcast-alerts-bar">
      {IconComponent && (
        <div
          className={classes.iconContainer}
          style={styleThemes.iconContainer[theme]}
        >
          <IconComponent
            classes={{ root: classes.icon }}
            className={classnames({ [classes.largeIcon]: largeIcon })}
          />
        </div>
      )}
      <div
        className={classes.barContainer}
        style={styleThemes.barContainer[theme]}
      >
        <span className={classnames("overline-heading", "bold", classes.subject)}>
          {subject}
        </span>
        {renderBody()}
      </div>
    </Paper>
  );
}
