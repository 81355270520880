import Bugsnag from "@bugsnag/browser";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import forEach from "lodash/forEach";
import React, {
  useContext, useRef, useMemo, createContext
} from 'react';

import { ErrorPage } from "./components/General/404Error";

const BugsnagContext = createContext({
  updateBugsnagMetaData: () => {}
});

// eslint-disable-next-line react/display-name
export const withBugsnag = Component => function (props) {
  const { updateBugsnagMetaData } = useContext(BugsnagContext);
  return <Component updateBugsnagMetaData={updateBugsnagMetaData} {...props} />;
};

function BugsnagWrapper({ children }) {
  const metaData = useRef({});

  const value = useMemo(() => {
    const updateBugsnagMetaData = (data) => {
      metaData.current = { ...metaData.current, ...data };
    };
    return { updateBugsnagMetaData };
  }, []);

  // Development mode by default
  window.bugsnagClient = {
    notify: (error) => {
      console.error("Development mode - Bugsnag error:", error);
    }
  };

  // Only initialize Bugsnag in production with an API key
  if (process.env.NODE_ENV === 'production' && process.env.BUGSNAG_API_KEY_CORE) {
    try {
      Bugsnag.start({
        apiKey: process.env.BUGSNAG_API_KEY_CORE,
        plugins: [new BugsnagPluginReact(React)],
        appVersion: process.env.HEROKU_RELEASE_VERSION,
        releaseStage: process.env.HEROKU_ENV,
        beforeSend(report) {
          forEach(metaData.current, (val, key) => {
            report.updateMetaData(key, val);
          });
        },
      });

      window.bugsnagClient = Bugsnag;
      const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

      return (
        <BugsnagContext.Provider value={value}>
          <ErrorBoundary FallbackComponent={ErrorPage}>
            {children}
          </ErrorBoundary>
        </BugsnagContext.Provider>
      );
    } catch (error) {
      console.error('Failed to initialize Bugsnag:', error);
      // Already set up development mode client above
    }
  }

  return (
    <BugsnagContext.Provider value={value}>
      {children}
    </BugsnagContext.Provider>
  );
}

export default BugsnagWrapper;

