import compact from "lodash/compact";
import map from "lodash/map";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { Row } from "react-grid-system";

import { formatCurrency } from "../../../../../../util/formatHelpers";

import { EarningsConversionRate, formatDate } from "./ConversionRate";
import MultiColStyledField from "./MultiColStyledField";
import Section from "./Section";
import StyledField from "./StyledField";
import usePlacementContext from "./usePlacementContext";

function PlacementAmount({ amount }) {
  const {
    recruiterPayoutTaxDeductionsAmountCents,
    recruiterMatchmakingFeeAmountCents,
    agencyIncentiveAmountCents,
  } = usePlacementContext();

  if (
    !recruiterPayoutTaxDeductionsAmountCents &&
    !recruiterMatchmakingFeeAmountCents &&
    !agencyIncentiveAmountCents &&
    !amount
  ) {
    return null;
  }

  return (
    <MultiColStyledField
      label="Placement Amount:"
      value={(
        <span style={{ fontWeight: 600 }}>
          {`${formatCurrency(amount, "USD")} USD`}
        </span>
      )}
    />
  );
}

PlacementAmount.propTypes = {
  amount: PropTypes.number.isRequired,
};

function WithholdingTax({ underline, setPresence }) {
  const { taxRuleName, recruiterPayoutTaxDeductionsAmountCents } =
    usePlacementContext();

  if (!recruiterPayoutTaxDeductionsAmountCents) return null;

  setPresence();

  return (
    <MultiColStyledField
      label="Withholding Tax:"
      tooltip={taxRuleName}
      value={(
        <span style={{ color: "red" }}>
          {`-${formatCurrency(
            recruiterPayoutTaxDeductionsAmountCents,
            "USD"
          )} USD`}
        </span>
      )}
      underline={underline}
    />
  );
}

function AgencyIncentive({ underline, setPresence }) {
  const {
    createdAt,
    agencyIncentiveAmountCents,
    agencyIncentiveCurrency,
    agencyIncentiveUsdConversion,
  } = usePlacementContext();

  if (!agencyIncentiveAmountCents) return null;

  setPresence();

  const tooltip = (() => {
    if (agencyIncentiveCurrency === "USD") return null;

    return `${formatCurrency(100, agencyIncentiveCurrency, {
      salaryConversion: true,
    })} ${agencyIncentiveCurrency} = $${agencyIncentiveUsdConversion} USD on ${formatDate(
      createdAt
    )}`;
  })();

  return (
    <MultiColStyledField
      label="Agency Incentive:"
      {...(tooltip ? { tooltip } : {})}
      underline={underline}
      value={(
        <span data-cy="agency-incentive-value" style={{ color: "green" }}>
          {`+${formatCurrency(agencyIncentiveAmountCents, "USD")} USD`}
        </span>
      )}
    />
  );
}

function AgencyIncentiveFee({ underline, setPresence }) {
  const { agencyIncentiveAmountCents } = usePlacementContext();

  if (!agencyIncentiveAmountCents) return null;

  setPresence();

  return (
    <MultiColStyledField
      label="Agency Incentive Fee:"
      value={(
        <span data-cy="agency-incentive-fee">
          {`-${formatCurrency(agencyIncentiveAmountCents * 0.1, "USD")} USD`}
        </span>
      )}
      underline={underline}
    />
  );
}

function MatchMakingFee({ underline, setPresence }) {
  const { recruiterMatchmakingFeeAmountCents } = usePlacementContext();

  if (!recruiterMatchmakingFeeAmountCents) return null;

  setPresence();

  return (
    <MultiColStyledField
      label="Matchmaking Fee:"
      value={(
        <span>
          {`-${formatCurrency(recruiterMatchmakingFeeAmountCents, "USD")} USD`}
        </span>
      )}
      underline={underline}
    />
  );
}

function ProcessingFee({ underline, setPresence }) {
  const { recruiterProcessingFeeAmountCents, deprecatedPricingModel } =
    usePlacementContext();

  if (!recruiterProcessingFeeAmountCents) return null;

  setPresence();

  return (
    <MultiColStyledField
      label={deprecatedPricingModel ? "Credit Card Collection Fee:" : "Processing Fee:"}
      value={(
        <span>
          {`-${formatCurrency(recruiterProcessingFeeAmountCents, "USD")} USD`}
        </span>
      )}
      underline={underline}
    />
  );
}

function PaymentProcessingFee({ underline, setPresence }) {
  const { recruiterPaymentProcessingFeeAmountCents } = usePlacementContext();

  if (!recruiterPaymentProcessingFeeAmountCents) return null;

  setPresence();

  return (
    <MultiColStyledField
      label="Credit Card Collection Fee:"
      value={(
        <span>
          {`-${formatCurrency(
            recruiterPaymentProcessingFeeAmountCents,
            "USD"
          )} USD`}
        </span>
      )}
      underline={underline}
    />
  );
}

function InternationalTax({ underline, setPresence }) {
  const {
    recruiterPayoutTaxAdditionsAmountCents, internationalTaxCountryCode
  } = usePlacementContext();

  if (!recruiterPayoutTaxAdditionsAmountCents) return null;

  const INTERNATIONAL_TAX_DESCRIPTION = {
    JPN: "10% JCT:",
    IND: "18% GST:",
    MEX: "16% VAT:",
  };

  setPresence();

  return (
    <MultiColStyledField
      label={INTERNATIONAL_TAX_DESCRIPTION[internationalTaxCountryCode]}
      value={(
        <span>
          {`+${formatCurrency(
            recruiterPayoutTaxAdditionsAmountCents,
            "USD"
          )} USD`}
        </span>
      )}
      underline={underline}
    />
  );
}

function FlatFee() {
  const {
    agencyPayoutRate,
    feeOption: { currency: feeCurrency },
  } = usePlacementContext();

  return (
    <>
      <MultiColStyledField
        label="Flat Fee:"
        value={`${formatCurrency(
          agencyPayoutRate,
          feeCurrency
        )} ${feeCurrency}`}
        underline
      />
      <EarningsConversionRate />
      <PlacementAmount amount={agencyPayoutRate} />
    </>
  );
}

function NonFlatFee() {
  const {
    agencyPayoutRate,
    requestSourceType,
    recruiterPayoutAmountCents,
    salaryCurrency,
    salaryCents,
    usdConversion,
  } = usePlacementContext();

  return (
    <>
      <EarningsConversionRate />
      {salaryCurrency !== "USD" && (
        <MultiColStyledField
          label="Converted Salary:"
          value={(
            <span style={{ fontWeight: 600 }}>
              {`${formatCurrency(salaryCents * usdConversion, "USD", {
                salaryCurrency,
              })} USD`}
            </span>
          )}
        />
      )}
      {/* todo(me): this is a flawed way of figuring out how the fee was derived, might fix */}
      <MultiColStyledField
        label={
          requestSourceType === "legacy" ? "Preferred Rate:" : "Community Rate:"
        }
        value={`${parseFloat(
          parseFloat((agencyPayoutRate * 100).toString()).toFixed(2)
        )}%`}
        underline
      />
      <PlacementAmount amount={recruiterPayoutAmountCents} />
    </>
  );
}

function FeesFAQ() {
  return (
    <div className="small gray" style={{ marginBottom: 16 }}>
      More info on{" "}
      <a
        href="https://help.recruitifi.com/en/articles/8313717-what-fees-may-apply-to-my-payout"
        target="_blank"
        rel="noopener noreferrer"
      >
        fees
      </a>
    </div>
  );
}

function EarningsCalculation() {
  const {
    createdAt,
    adjustedRecruiterPayoutAmountCents,
    salaryCents,
    salaryCurrency,
    deprecatedPricingModel,
    feeOption: { feeType },
  } = usePlacementContext();

  const [underlineIdx, setUnderlineIdx] = useState(0);

  const setAdjustmentItemPresence = useCallback(
    (idx) => () => {
      setUnderlineIdx((currIdx) => Math.max(currIdx, idx));
    },
    [setUnderlineIdx]
  );

  const AdjustmentComponents = compact([
    WithholdingTax,
    AgencyIncentive,
    deprecatedPricingModel ? AgencyIncentiveFee : null,
    MatchMakingFee,
    ProcessingFee,
    PaymentProcessingFee,
    InternationalTax,
  ]);

  const totalEarningsValue = `${formatCurrency(
    adjustedRecruiterPayoutAmountCents,
    "USD"
  )} ${"USD"}`;

  return (
    <Section title="Earnings Calculation">
      <MultiColStyledField
        label={<h5>Total Earnings</h5>}
        value={<h5 data-cy="total-earnings-value">{totalEarningsValue}</h5>}
      />
      <Row>
        <StyledField label="Hire Date" value={formatDate(createdAt)} />
      </Row>
      <MultiColStyledField
        label="Salary:"
        value={`${formatCurrency(
          salaryCents,
          salaryCurrency
        )} ${salaryCurrency}`}
      />
      {feeType === "flat_fee" ? <FlatFee /> : <NonFlatFee />}
      {map(AdjustmentComponents, (Component, idx) =>
        React.createElement(Component, {
          key: idx,
          setPresence: setAdjustmentItemPresence(idx),
          underline: underlineIdx === idx,
        }))}
      <MultiColStyledField
        label={<span style={{ fontWeight: 600 }}>Total Earnings</span>}
        value={<span style={{ fontWeight: 600 }}>{totalEarningsValue}</span>}
      />
      {!deprecatedPricingModel && <FeesFAQ />}
    </Section>
  );
}

export default EarningsCalculation;
