import MobileStepper from "@material-ui/core/MobileStepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import merge from "lodash/merge";
import React from "react";
import { connect } from "react-redux";

class FormStepper extends React.Component {
  renderBrowserStepper() {
    const {
      activeStep,
      steps,
      helperTextStyle,
      stepHelperTexts,
      overrideStepperStyle = {},
      overrideStepStyle = {},
      overrideSpanStyle = {},
    } = this.props;

    return (
      <div className="stepper-container" style={{ display: "block" }}>
        <Stepper
          style={merge({}, { paddingBottom: 0 }, overrideStepperStyle)}
          activeStep={activeStep}
          alternativeLabel
        >
          {steps.map((label) => (
            <Step key={label} style={overrideStepStyle}>
              <StepLabel>
                <span
                  className="overline-heading"
                  style={overrideSpanStyle}
                >
                  {label}
                </span>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {Boolean(stepHelperTexts && stepHelperTexts[activeStep]) ? (
          <div style={helperTextStyle}>{stepHelperTexts[activeStep]}</div>
        ) : (
          null
        )}
      </div>
    );
  }

  renderMobileStepper() {
    return (
      <div className="stepper-container">
        <MobileStepper
          activeStep={this.props.activeStep}
          backButton={this.props.backButton}
          nextButton={this.props.nextButton}
          position="static"
          steps={this.props.steps.length}
        />
      </div>
    );
  }

  render() {
    const { mobileView } = this.props;
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {mobileView ? this.renderMobileStepper() : this.renderBrowserStepper()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mobileView: state.ui.browser.lessThan.medium,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FormStepper);
