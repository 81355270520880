import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import round from "lodash/round";
import moment from "moment";
import React, { useState, useEffect, useMemo } from "react";

import { useSelector } from "react-redux";

import { fetchJobcastEmployerRatings } from "../../../../../actions/shared/jobcastActions";
import { makeGetJobCast } from "../../../../../selectors/jobcastSelectors";
import { mapKeysToCamelCase } from "../../../../../util/formatHelpers";
import LoadingPage from "../../../../General/LoadingPage";
import StarRatingDisplay from "../../../../General/StarRatingDisplay";

const styles = () => ({
  cardContainer: {
    width: 215,
    height: 200,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-around",
    padding: 12,
    gap: 16
  },
  header: {
    marginBottom: 3,
  },
  valueContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    whiteSpace: "pre",
  },
  feedbackRatingValue: {
    "&$positive": { color: "#3C9255" },
    "&$negative": { color: "#FF6D6D" },
  },
  positive: {},
  negative: {},
});

function JobCastRatingValue({ jobcastRatings }) {
  const { average, numRatings } = jobcastRatings;

  return numRatings >= 3 ? (
    <>
      <StarRatingDisplay rating={average} />
      {` ${round(average, 1)}`}
    </>
  ) : (
    "Not enough ratings"
  );
}

function OverallRatingValue({ overall }) {
  const { average, numRatings } = overall;

  return numRatings ? (
    <>
      <StarRatingDisplay rating={average} />
      {` ${round(average, 1)}`}
    </>
  ) : (
    "Not enough ratings"
  );
}

function FeedbackRatingValue({ feedbackRatings, classes }) {
  const { percentage, numRatings } = feedbackRatings;

  return numRatings >= 3 ? (
    <>
      <span
        className={classnames(classes.feedbackRatingValue, {
          [classes.positive]: percentage >= 0.8,
          [classes.negative]: percentage < 0.6,
        })}
      >
        {`${round(percentage * 100)}% `}
      </span>
      of feedback is helpful
    </>
  ) : (
    "Not enough ratings"
  );
}

function RatingsPopoverContent({ jobcastId, classes, full = false }) {
  const [ratingsData, setRatingsData] = useState(null);
  const getJobcast = useMemo(() => makeGetJobCast(), []);
  const jobcast = useSelector((state) => getJobcast(state, jobcastId));
  const { type, individualLastActivityAt } = jobcast?.attributes || {};

  useEffect(() => {
    fetchJobcastEmployerRatings(jobcastId, type).then((res) => {
      setRatingsData(mapKeysToCamelCase(res.data));
    });
  }, [jobcastId, type]);

  const renderContent = () => {
    const { jobcastRatings, feedbackRatings, avgTimeToSort } = ratingsData;
    const timeToSort = Math.round(avgTimeToSort);
    const lastActivity = moment(individualLastActivityAt).fromNow();

    return (
      <>
        <div>
          <div className={classnames("overline-heading", classes.header)}>
            Overall Rating
          </div>
          <div className={classnames(classes.valueContainer, "small")}>
            <OverallRatingValue {...{ overall: ratingsData.overall }} />
          </div>
        </div>
        <div>
          <div className={classnames("overline-heading", classes.header)}>
            JobCast Rating
          </div>
          <div className={classnames(classes.valueContainer, "small")}>
            <JobCastRatingValue {...{ jobcastRatings }} />
          </div>
        </div>
        <div>
          <div className={classnames("overline-heading", classes.header)}>
            Feedback Rating
          </div>
          <div className={classnames(classes.valueContainer, "small")}>
            <FeedbackRatingValue {...{ feedbackRatings, classes }} />
          </div>
        </div>
        <div>
          <div>
            <span className={classnames("subtitle-2", classes.header)}>
              Time to Sort:
            </span>
            <span className="small" style={{ paddingLeft: 3 }}>
              {timeToSort
                ? `${timeToSort} ${timeToSort === 1 ? "day" : "days"}`
                : "n/a"}
            </span>
          </div>
        </div>
        {full && (
          <div>
            <div>
              <span className={classnames("subtitle-2", classes.header)}>
                Active:
              </span>
              <span className="small" style={{ paddingLeft: 3 }}>
                {lastActivity}
              </span>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className={classes.cardContainer}>
      {ratingsData ? renderContent() : <LoadingPage />}
    </div>
  );
}

export default withStyles(styles)(RatingsPopoverContent);
